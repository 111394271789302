<template lang="pug">
  v-col
    v-card(
      outlined
    )
      h5.pl-4.grey--text.text--darken-2 PATENT PENDING 63/521,804
      v-card-title
        v-layout(
          flex
        )
          h3.grey--text.text--darken-2 Orders
          v-btn.ml-auto(
            color="secondary"
            :to="{ name: 'drawers' }"
          )
            v-icon(
              left
            ) mdi-plus
            | New Order
      OrdersTable(
        :query="{ shop_id: shopId }"
        :shopId="shopId"
      )
</template>

<script>
import OrdersTable from '@/components/tables/OrdersTable'

export default {
  name: 'ShopOrders',
  components: {
    OrdersTable
  },
  props: {
    shopId: {
      type: String,
      required: true
    },
    verbiage: {
      type: String,
      required: false
    }
  },
  data: () => ({

  })
}
</script>

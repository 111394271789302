<template lang="pug">
  v-data-table.clickable(
    :headers="headers"
    :loading="areOrdersPending"
    no-data-text="No Orders Found"
    :options.sync="options"
    :server-items-length="haveOrdersLoaded ? paginationData.default.mostRecent.total : 0"
    :items="orders"
    @click:row="(item) => $router.push({name: 'order', params: { orderId: item.id }})"
    :footer-props="{ itemsPerPageOptions: [5,10,15] }"
  )
    template(v-slot:item.shop_id="{ item: order }") {{ order.shop.name }}
    template(v-slot:item.createdAt="{ item: order }") {{ $day(order.createdAt).format('M/D/YY h:mm A') }}
    template(#body.prepend)
      tr
        td(colspan="100")
          v-btn(
            color="secondary"
            large
            block
            :to="{ name: 'drawers' }"
          )
            v-icon(
              left
            ) mdi-plus
            | New Order at {{ shop.name }}
    template(v-slot:item.action="{ item: order }")
      DownloadOrderButton.pl-0(
        :shop="shop"
      :order="order"
      :text="null"
      )

</template>

<script>

import { useFind, useGet } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import DownloadOrderButton from '@/components/DownloadOrderButton'

export default {
  name: 'OrdersTable',
  components: { DownloadOrderButton },
  props: {
    query: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: 'Date',
            value: 'createdAt'
          },
          {
            text: 'Submitted By',
            value: 'submittedBy'
          },
          {
            text: 'Action',
            value: 'action'
          }
        ]
      }
    },
    shopId: {
      type: String,
      required: false
    },
    regionId: {
      type: String,
      required: false
    }
  },
  setup (props, context) {
    const { Order } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    const { Shop } = context.root.$FeathersVuex.api

    const { item: shop } = useGet({
      model: Shop,
      id: props.shopId
    })

    const { Region } = context.root.$FeathersVuex.api

    const { item: region } = useGet({
      model: Region,
      id: props.regionId
    })

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      // Add a search term if it's not null, can $or multiple keys
      if (search.value !== null && search.value !== '') {
        returnQuery.$or = [
          {
            name: {
              $regex: search.value,
              $options: 'gi'
            }
          },
          {
            state: {
              $regex: search.value,
              $options: 'gi'
            }
          }
        ]
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      return { query: queryObj.value }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      return { query: { ...queryObj.value } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: orders, isPending: areOrdersPending, paginationData, haveLoaded: haveOrdersLoaded } = useFind({
      model: Order,
      params,
      fetchParams,
      paginate: true
    })

    return {
      shop,
      region,
      search,
      options,
      sortBy,
      limit,
      orders,
      queryObj,
      params,
      fetchParams,
      areOrdersPending,
      haveOrdersLoaded,
      paginationData
    }
  },
  data: () => ({

  })
}
</script>
